import CircleIcon from "@mui/icons-material/Circle";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { Button } from "@mui/material";
import AddDescription from "components/AddDescription/AddDescription";
import AddRow from "components/AddRow/AddRow";
import { getUserDocument } from "models/Document";
import { useSelector } from "react-redux";
import snackbar from "utils/snackbar";
import ReactJson from "react-json-view";

const PopoverCard = ({ row }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Typography
        sx={{
          color:
            row?.kind === "Diagnosis"
              ? "green"
              : row?.kind === "Procedure"
              ? "blue"
              : row?.kind === "Medication"
              ? "orange"
              : "black",
          fontWeight: "bold",
          cursor: "pointer",
          ...(row?.ignored
            ? {
                border: row?.negative
                  ? "3px solid red"
                  : `2px dashed ${
                      row?.kind === "Diagnosis"
                        ? "green"
                        : row?.kind === "Procedure"
                        ? "blue"
                        : row?.kind === "Medication"
                        ? "orange"
                        : "black"
                    }`,
                padding: 0.3,
              }
            : {
                borderBottom: row?.negative
                  ? "3px solid red"
                  : `2px dashed ${
                      row?.kind === "Diagnosis"
                        ? "green"
                        : row?.kind === "Procedure"
                        ? "blue"
                        : row?.kind === "Medication"
                        ? "orange"
                        : "black"
                    }`,
              }),
          mx: 0.5,
        }}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        component="span"
      >
        {row?.text}
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Card sx={{ minWidth: "500px" }}>
            <CardContent sx={{ mx: 3 }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ textAlign: "center" }}
              >
                {row?.text}
                <br />
                <Typography variant="body1" color="text.secondary">
                  score: {row?.confidenceScore}
                </Typography>
              </Typography>
              <Divider />
              {row?.dataSources?.map((dataSource, idx) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    key={Math.floor(Math.random() * 100000 + (idx + 1))}
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      mb: 2,
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight="bold"
                      sx={{ width: "20%" }}
                    >
                      {dataSource?.name}
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        width: "80%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontWeight="bold"
                      >
                        {dataSource?.entityId}
                      </Typography>
                    </Box>
                  </Box>
                  {idx < row?.dataSources?.length - 1 && <Divider />}

                  {dataSource?.sources?.map((source, idx) => (
                    <Box
                      key={Math.floor(Math.random() * 100000 + (idx + 1))}
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        mb: 2,
                        width: "80%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ width: "20%" }}
                      >
                        {source?.code}
                      </Typography>
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {source?.description}
                        </Typography>
                      </Box>
                      {idx < dataSource?.sources?.length - 1 && <Divider />}
                    </Box>
                  ))}
                </Box>
              ))}
            </CardContent>
          </Card>
        </Popover>
      </Typography>
    </>
  );
};

const MainListItem = ({
  row,
  checked,
  handleToggle,
  labelId,
  setAddDescriptionOpen,
  setSelectedRow,
  documentId,
  fetchDocument,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem sx={{ px: 2, py: 1 }}>
        <ListItemButton role={undefined} dense>
          <IconButton
            edge="end"
            aria-label={open ? "hide" : "show"}
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            {open ? (
              <ExpandLess sx={{ fontSize: "1.8rem" }} />
            ) : (
              <ExpandMore sx={{ fontSize: "1.8rem" }} />
            )}
          </IconButton>
          <ListItemText
            id={labelId}
            primary={
              <Typography
                fontSize="large"
                fontWeight="bold"
                variant="body1"
                component="div"
              >
                {`${row?.Text} (${row?.childs?.length})`}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ mx: "5%" }} component="div">
          {row?.childs?.map((value, idx) => {
            const labelId = `checkbox-list-label-${value.Id}`;
            return (
              <RowListItem
                key={`row-${idx}`}
                row={value}
                checked={checked}
                documentId={documentId}
                handleToggle={handleToggle}
                labelId={labelId}
                fetchDocument={fetchDocument}
                setAddDescriptionOpen={setAddDescriptionOpen}
                setSelectedRow={setSelectedRow}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const DescriptionSubListItem = ({
  row,
  checked,
  handleToggle,
  labelId,
  current,
}) => {
  return (
    <ListItem sx={{ px: 2, py: 1 }}>
      <ListItemButton role={undefined} dense>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={
              checked.indexOf(
                `${current?.entity_id}@${current?.id}@${row?.code}`
              ) !== -1
            }
            tabIndex={-1}
            disableRipple
            onClick={handleToggle(
              `${current?.entity_id}@${current?.id}@${row?.code}`
            )}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </ListItemIcon>

        <ListItemText
          id={labelId}
          primary={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ width: "20%" }}
                fontSize="large"
                variant="body1"
                component="div"
              >
                {row?.code}
              </Typography>
              <Typography
                sx={{ width: "80%" }}
                fontSize="large"
                variant="body1"
                component="div"
              >
                {row?.description}
              </Typography>
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

const DescriptionListItem = ({
  row,
  checked,
  handleToggle,
  labelId,
  current,
  fetchDocument,
}) => {
  return (
    <>
      <ListItem sx={{ px: 2, py: 1 }}>
        <ListItemButton role={undefined} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(`${current?.id}@${row?.id}`) !== -1}
              tabIndex={-1}
              disableRipple
              onClick={handleToggle(`${current?.id}@${row?.id}`)}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>

          <ListItemText
            id={labelId}
            primary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ width: "20%" }}
                  fontSize="large"
                  variant="body1"
                  component="div"
                  fontWeight="bold"
                >
                  {row?.name}
                </Typography>
                <Typography
                  sx={{ width: "80%" }}
                  fontSize="large"
                  variant="body1"
                  component="div"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {row?.entityId}
                </Typography>
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
      <Box>
        <List sx={{ mx: "8%" }} component="div">
          {row?.sources?.map((source, idx) => (
            <DescriptionSubListItem
              key={Math.floor(Math.random() * 100000 + (idx + 1))}
              checked={checked}
              handleToggle={handleToggle}
              row={source}
              index={idx}
              current={row}
              labelId={`description-sub-list-item-${row.Id}-${idx}`}
            />
          ))}
        </List>
      </Box>
    </>
  );
};

const RowListItem = ({
  row,
  checked,
  handleToggle,
  labelId,
  fetchDocument,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem sx={{ px: 2, py: 1 }} key={row?.Id}>
        <ListItemButton role={undefined} dense>
          <IconButton
            edge="end"
            aria-label={open ? "hide" : "show"}
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            {open ? (
              <ExpandLess sx={{ fontSize: "1.8rem" }} />
            ) : (
              <ExpandMore sx={{ fontSize: "1.8rem" }} />
            )}
          </IconButton>

          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(String(row?.id)) !== -1}
              tabIndex={-1}
              disableRipple
              onClick={handleToggle(String(row?.id))}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>

          <ListItemText
            id={labelId}
            primary={
              <>
                <Typography fontSize="large" variant="body1" component="div">
                  {`${row?.text} (${row?.dataSources?.length})`}{" "}
                </Typography>
                {row.negative && (
                  <small style={{ color: "red" }}>Negative</small>
                )}
              </>
            }
            secondary={
              <Typography variant="body2" component="div">
                {row?.traitsArr?.join(", ")}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ mx: "8%" }} component="div">
          {row?.dataSources?.map((st, idx) => (
            <DescriptionListItem
              key={Math.floor(Math.random() * 100000 + (idx + 1))}
              checked={checked}
              fetchDocument={fetchDocument}
              handleToggle={handleToggle}
              row={st}
              index={idx}
              current={row}
              labelId={`description-list-item-${row.Id}-${idx}`}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default function AzureEditableDocument({ documentId }) {
  const [checked, setChecked] = React.useState([]);
  const [customizedText, setCustomizedText] = React.useState("");
  const [addRowOpen, setAddRowOpen] = React.useState(false);
  const [addDescriptionOpen, setAddDescriptionOpen] = React.useState(false);
  const [kind, setKind] = React.useState("Diagnosis");
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [userDocument, setUserDocument] = React.useState({});

  const { currentUser } = useSelector((state) => state.auth);

  const fetchDocument = async (id) => {
    setLoading(true);
    const doc = await getUserDocument(id);
    setUserDocument(doc);
    setLoading(false);
  };

  React.useEffect(() => {
    if (documentId) {
      fetchDocument(documentId);
    }
  }, [documentId]);

  const handleToggle = (value) => () => {
    value = String(value);
    const currentIndex = checked.indexOf(value);
    let newChecked = [...checked];
    if (value?.includes("@")) {
      let parent = "";
      const splittedValue = value.split("@"),
        numberOfParents = (value?.match(/@/g) || []).length;
      for (let i = 0; i < numberOfParents; i++) {
        parent += `${i > 0 ? "@" : ""}${splittedValue[i]}`;
        if (currentIndex === -1 && checked.indexOf(parent) === -1)
          newChecked.push(parent);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      newChecked = newChecked.filter((val) => !val.includes(`${value}@`));
    }
    setChecked(newChecked);
  };

  React.useEffect(() => {
    if (userDocument?.entities?.length > 0) {
      let text = [];
      userDocument?.entities?.forEach((entity, idx, arr) => {
        text.push(
          <span key={Math.floor(Math.random() * 100000 + (idx + 1))}>
            {String(userDocument?.text).substring(
              idx === 0 ? 0 : arr[idx - 1]?.offset + arr[idx - 1]?.length,
              entity?.offset
            )}
          </span>
        );
        if (!!entity.kind)
          text.push(
            <PopoverCard
              row={entity}
              key={Math.floor(Math.random() * 100000 + (idx + 1))}
            />
          );
        else text.push(entity.text);
        if (
          idx === arr?.length - 1 &&
          entity?.offset + entity?.length < userDocument?.text?.length - 1
        ) {
          text.push(
            <span>
              {String(userDocument?.text).substring(
                entity?.offset + entity?.length,
                userDocument?.text?.length - 1
              )}
            </span>
          );
        }
      });
      setCustomizedText(text);
    }
  }, [userDocument]);

  const handleExport = () => {
    snackbar.info("Preparing Data to be Exported.");
    const rows = [["Text", "Category", "Name", "Code"]];
    checked.forEach((value) => {
      if (!value.includes("@")) {
        const entity = userDocument?.entities?.find(
          (v) => v.id === parseInt(value)
        );
        rows.push([entity.text, entity.kind, "", ""]);
        if (entity?.dataSources?.length > 0) {
          entity?.dataSources?.forEach((eds) => {
            checked.includes(`${entity?.id}@${eds?.id}`) &&
              rows.push(["", "", eds?.name, eds?.entityId]);
            if (eds?.sources?.length > 0) {
              eds?.sources?.forEach(
                (dsss) =>
                  checked.includes(`${entity?.id}@${eds?.id}@${dsss?.code}`) &&
                  rows.push(["", "", dsss?.code, dsss?.description])
              );
            }
          });
        }
      }
    });
    const csvContent =
      "data:text/csv;charset=utf-8," +
      rows.map((e) => e.map((s) => '"' + s + '"').join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
    snackbar.success("File Exported Successfully.");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 5,
        textAlign: "center",
        bgcolor: "background.paper",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: "fixed",
            zIndex: "1000000000000",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            height: "100%",
            width: "100%",
            bgcolor: "#000000B3",
            overflow: "auto",
          }}
        >
          <CircularProgress size={100} />
          <Typography
            variant="bod1"
            component="div"
            color="#FFF"
            mt={5}
            fontWeight="bold"
          >
            Preparing Your Data Please Wait...
          </Typography>
        </Box>
      )}
      <AddRow
        setOpen={setAddRowOpen}
        open={addRowOpen}
        kind={kind}
        fetchDocument={fetchDocument}
        userDocument={userDocument}
      />
      <AddDescription
        setOpen={setAddDescriptionOpen}
        open={addDescriptionOpen}
        current={selectedRow}
        fetchDocument={fetchDocument}
      />
      <Typography
        component="div"
        variant="body1"
        sx={{
          border: "1px solid #ddd",
          p: 3,
          mb: 1,
          // mx: 5,
          borderRadius: "5px",
          lineHeight: "2.5rem",
          width: "90%",
        }}
      >
        {customizedText}
      </Typography>
      <Typography
        component="div"
        variant="caption"
        sx={{
          border: "1px solid #ddd",
          p: 1,
          mb: 1,
          borderRadius: "5px",
          lineHeight: "1rem",
          width: "20%",
        }}
      >
        <b>Number of Characters: </b>
        {userDocument?.text?.length}
      </Typography>
      <Box
        sx={{
          border: "1px solid #ddd",
          px: 3,
          py: 1,
          mb: 5,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircleIcon sx={{ fontSize: "medium", color: "green" }} />
        <Typography sx={{ ml: 1 }} component="div" variant="body1">
          Diagnosis
        </Typography>
        <CircleIcon sx={{ fontSize: "medium", color: "blue", ml: 3 }} />
        <Typography sx={{ ml: 1 }} component="div" variant="body1">
          Procedures
        </Typography>
        <CircleIcon sx={{ fontSize: "medium", color: "orange", ml: 3 }} />
        <Typography sx={{ ml: 1 }} component="div" variant="body1">
          Medication
        </Typography>
        <CircleIcon sx={{ fontSize: "medium", color: "red", ml: 3 }} />
        <Typography sx={{ ml: 1 }} component="div" variant="body1">
          Negative
        </Typography>
      </Box>
      <List sx={{ width: "90%", bgcolor: "background.paper", mb: 20 }}>
        {userDocument?.entities?.length > 0 &&
          [
            {
              Id: "main-diagnosis",
              Text: "Diagnosis",
              childs: userDocument?.entities?.filter(
                (aa) => aa?.kind === "Diagnosis"
              ),
              kind: "Diagnosis",
            },
            {
              Id: "main-procedures",
              Text: "Procedures",
              childs: userDocument?.entities?.filter(
                (aa) => aa?.kind === "Procedure"
              ),
              kind: "Procedure",
            },
            {
              Id: "main-medication",
              Text: "Medication",
              childs: userDocument?.entities?.filter(
                (aa) => aa?.kind === "Medication"
              ),
              kind: "Medication",
            },
          ]?.map((value, idx) => {
            const labelId = `checkbox-list-label-${value?.Id}`;
            return (
              <MainListItem
                key={`main-${idx}`}
                setKind={setKind}
                setAddRowOpen={setAddRowOpen}
                setAddDescriptionOpen={setAddDescriptionOpen}
                setSelectedRow={setSelectedRow}
                row={value}
                checked={checked}
                handleToggle={handleToggle}
                labelId={labelId}
                documentId={userDocument?.id}
                fetchDocument={fetchDocument}
              />
            );
          })}
      </List>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "70%",
            bgcolor: "#f1f1f1",
            p: 3,
            mb: "5rem",
          }}
        >
          <ReactJson
            collapsed
            src={{
              Entities: userDocument?.entities,
              Relations: userDocument?.entityRelations,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "70%",
          bgcolor: "#fff",
          position: "fixed",
          boxShadow: "0px -2px 5px #888888",
          py: 2,
          bottom: 0,
          maxHeight: 100,
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
        }}
      >
        <Button
          disabled={checked.length === 0}
          variant="contained"
          onClick={handleExport}
          color="success"
          sx={{ color: "#fff", mx: 2, my: 1 }}
        >
          Export Data
        </Button>
      </Box>
    </Box>
  );
}
