import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import snackbar from "utils/snackbar";
import { snackbarErrorMsg } from "config/constants";
import { editUser } from "models/User";
import Select from "@mui/material/Select";
import {
  Collapse,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
} from "@mui/material";

function EditUser({
  open,
  setOpen,
  fetchUsers,
  corporate = false,
  userTypes = [],
  user,
}) {
  const [submissions, setSubmissions] = useState(0);
  const [userType, setUserType] = useState("");
  const [active, setActive] = useState(true);

  useEffect(() => {
    setSubmissions(user?.submissions);
    setUserType(user?.type_id);
    setActive(user?.active);
  }, [user]);

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  const handleActiveChange = (event) => {
    setActive(event.target.checked);
  };

  const handleSubmissionsChange = (e) => {
    const val = parseInt(e.target.value);
    setSubmissions(val);
  };

  const handleSubmissionsBlur = (e) => {
    const val = parseInt(e.target.value);
    setSubmissions(!val || val < 0 ? 0 : val > 1000 ? 1000 : val);
  };

  const handleSubmit = async () => {
    try {
      setOpen(false);

      const data = {
        submissions,
        userType,
        active,
      };

      await editUser(user?.id, data);

      await fetchUsers(corporate);

      snackbar.toast("User Updated Successfully.");
    } catch (error) {
      snackbar.error(error?.response?.data?.data?.message || snackbarErrorMsg);
      console.log(error);
    } finally {
      resetForm();
    }
  };

  const resetForm = () => {
    setSubmissions(5);
    setUserType("");
  };

  const isDisabled =
    user?.type === "CLIENT" && (!submissions?.toString() || !userType);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        setOpen(false);
        resetForm();
      }}
      aria-labelledby="add-user-first-name"
      aria-describedby="add-user-description"
    >
      <DialogTitle id="add-user-first-name">Edit Client</DialogTitle>
      <DialogContent>
        <Collapse in={user?.type === "CLIENT"} timeout={400}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="code-type">Code Type</InputLabel>
            <Select
              labelId="code-type"
              id="code-type-select"
              value={userType}
              label="Code Type"
              onChange={handleUserTypeChange}
            >
              {userTypes.map((ut) => (
                <MenuItem value={ut?.id}>
                  <b>{ut?.name}</b>
                  <small style={{ marginLeft: 20 }}>
                    {[
                      `Diagnosis: ${ut?.diagnosis}`,
                      `Procedure: ${ut?.procedure}`,
                      `Medication: ${"- - - - -"}`,
                    ].join(", ")}
                  </small>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            margin="normal"
            value={submissions}
            autoComplete="remove-it"
            onChange={handleSubmissionsChange}
            onBlur={handleSubmissionsBlur}
            id="submissions"
            label="Submissions Limit"
            type="number"
            fullWidth
            variant="outlined"
          />
        </Collapse>

        <FormControlLabel
          value={active}
          control={
            <Switch
              checked={active}
              color="primary"
              onChange={handleActiveChange}
            />
          }
          label="Active"
          labelPlacement="start"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={isDisabled}
          variant="contained"
        >
          Update User
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditUser;
