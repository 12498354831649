import api from "utils/api";

export const submitUserEntity = async (id, payload) => {
  return await api
    .post(`/documents/${id}/entities`, payload)
    .then((res) => {
      const {
        data: {
          data: { entity },
        },
      } = res;
      return entity;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const deleteUserEntity = async (documentId, id) => {
  return await api
    .delete(`/documents/${documentId}/entities/${id}`)
    .then((res) => {
      const {
        data: { data },
      } = res;
      return data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
