import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LoadingButton from "@mui/lab/LoadingButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import format from "date-fns/format";
import Layout from "components/Layout/Layout";
import EditableDocument from "components/AzureEditableDocument/AzureEditableDocument";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import { getUserDocuments } from "models/Document";
import AzureOriginDocument from "components/AzureOriginDocument/AzureOriginDocument";
import CompareDocument from "pages/CompareDocument/CompareDocument";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Row(props) {
  const { row, fetchData } = props;

  const [open, setOpen] = React.useState(false);
  const [openCompare, setOpenCompare] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchData();
  };

  const handleClickOpenCompare = (id) => {
    setSelectedRow(id);
    setOpenCompare(true);
  };

  const handleCloseCompare = () => {
    setOpenCompare(false);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">{row?.id}</TableCell>
        <TableCell align="center">{row?.name}</TableCell>
        <TableCell align="center">
          {row?.user?.name} <small>({row?.user?.username})</small>
        </TableCell>
        <TableCell align="center">
          {format(new Date(row?.created_at), "dd MMM yyyy - hh:mm a")}
        </TableCell>
        <TableCell align="center">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <Button variant="outlined" onClick={handleClickOpen}>
                Open Details
              </Button>
              <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
              >
                <AppBar sx={{ position: "relative" }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      sx={{ ml: 2, flex: 1 }}
                      variant="h6"
                      component="div"
                    >
                      {row?.job_name} Details
                    </Typography>
                  </Toolbar>
                </AppBar>
                <EditableDocument documentId={row?.id} />
              </Dialog>
            </div>
            <Box>
              {!row.manual && (
                <>
                  <Button
                    sx={{ mx: 2 }}
                    variant="outlined"
                    onClick={() => handleClickOpenCompare(row?.id)}
                  >
                    Compare Versions
                  </Button>
                  <CompareDocument
                    handleCloseCompare={handleCloseCompare}
                    openCompare={openCompare}
                    key={row.id}
                    selectedRow={selectedRow}
                  />
                </>
              )}
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function AzureDocuments() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const { currentUser } = useSelector((state) => state.auth);

  const fetchData = async () => {
    setLoading(true);
    setData(await getUserDocuments("AZURE", currentUser.id));
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <Box
        sx={{
          p: 3,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingButton
          sx={{ my: 5, width: "80%" }}
          loading={loading}
          loadingPosition="center"
          startIcon={<RefreshIcon />}
          variant="contained"
          size="large"
          onClick={() => fetchData()}
        >
          Refresh
        </LoadingButton>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Id</TableCell>
                <TableCell align="center">Job Name</TableCell>
                <TableCell align="center">User</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, idx) => (
                <Row key={idx} row={row} fetchData={fetchData} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
}
