import AuthPage from "pages/AuthPage/AuthPage";
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";
import paths from "./paths";

function AppRoutes() {
	return (
		<Router>
			<Routes>
				<Route exact key="/login" path="/login" element={<AuthPage />} />
				{paths.map(({ path, isPrivate, exact, component }) =>
					isPrivate ? (
						<Route exact={exact} key={path} path={path} element={<PrivateRoute children={component} />} />
					) : (
						<Route exact={exact} key={path} path={path} element={component} />
					)
				)}
				<Route exact={true} key="*" path="*" element={<Navigate to="/" />} />
			</Routes>
		</Router>
	);
}

export default AppRoutes;
