import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";

import { makeStyles } from "@mui/styles";
import SwipeableTemporaryDrawer from "components/SideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";

// import { useLocation } from "react-router-dom";
import Uploading from "components/Uploading/Uploading";
import { logoutUser } from "redux/features/auth.slice";

import { Logo } from "assets/lottie/svg-component";
import { Typography } from "@mui/material";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    "&&&": {
      backgroundImage:
        "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
    },
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Layout({ children, cta, uploading, uploadProgress }) {
  const dispatch = useDispatch();
  // const location = useLocation();
  const classes = useStyles();

  const {
    auth: { currentUser },
    upload: { progress, isUploading },
  } = useSelector((state) => state);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSideMenuChange = () => {
    setMenuOpen((st) => !st);
  };

  return (
    <Box sx={{ minHeight: "100%" }}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: "white" }}
            onClick={handleSideMenuChange}
          >
            <MenuIcon />
          </IconButton>
          <Box
            p={1}
            color="#fff"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              ml: 5,
            }}
          >
            <Box clone="true">
              <Logo height="55" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Box sx={{ mr: 5 }}>{cta}</Box>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  sx={{
                    width: "2.6rem",
                    height: "2.6rem",
                    color: "#fff",
                    bgcolor: "#bbb",
                  }}
                >
                  {currentUser?.user?.firstName?.charAt(0)?.toUpperCase()}
                  {currentUser?.user?.lastName?.charAt(0)?.toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={() => dispatch(logoutUser())}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableTemporaryDrawer state={menuOpen} setState={setMenuOpen} />

      <main className={classes.content}>
        {/* {uploading && (
          <LinearProgress variant="determinate" value={uploadProgress} color="secondary" />
        )} */}
        {isUploading && <Uploading progress={progress} />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: { lg: 8, md: 6, sm: 4, xs: 2 },
            py: { lg: 4, md: 3, sm: 2, xs: 1 },
            flex: 1,
          }}
        >
          {children}
        </Box>
      </main>
    </Box>
  );
}

export default Layout;
