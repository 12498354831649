import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import clsx from "clsx";
import { startAuth } from "redux/features/auth.slice";
import useForm from "utils/hooks/useForm";
import Paper from "@mui/material/Paper";
import PasswordVisibility from "components/PasswordVisibility/PasswordVisibility";
import useStyles from "./styles";
import Box from "@mui/material/Box";
import { Logo } from "assets/lottie/svg-component";
import Typography from "@mui/material/Typography";
import ForgotPassword from "components/ForgotPassword/ForgotPassword";

const formFields = [
	{ item: "email", label: "Username", type: "text" },
	{ item: "password", label: "Password", type: "password" },
];

const validate = (name, val) => {
	const value = val.trim();
	switch (name) {
		case "email":
			return value.length < 1 ? `Enter your Username` : "";

		case "password":
			return value.length < 1 ? "Enter your password" : "";

		default:
			return "";
	}
};

function Login({ formHeader }) {
	const { fields, validation, handleChange, handleValidation, isSubmitDisabled, setValidation } = useForm(
		formFields,
		validate
	);
	const [showPassword, toggleShowPassword] = useState(false);
	const [openForget, setOpenForget] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	const errors = useSelector((state) => state.auth.errors);

	useEffect(() => {
		formFields.forEach((f) => {
			errors?.[f.item] &&
				setValidation((st) => ({
					...st,
					[f.item]: errors[f.item],
				}));
		});
	}, [errors, setValidation]);

	const handleSubmit = (evt) => {
		evt.preventDefault();
		if (isSubmitDisabled()) return;

		dispatch(startAuth({ fields }));
	};

	return (
		<Paper className={classes.root}>
			{/* <Box
		  fontSize="2.4rem"
		  color="#fff"
		  p={0.5}
		  display="flex"
		  style={{
			backgroundColor: '#fff',
			borderBottomLeftRadius: '20px',
			borderBottomRightRadius: '20px',
		  }}
		> */}
			<ForgotPassword open={openForget} setOpen={setOpenForget} />
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					textAlign: "center",
				}}
				width="4em"
			>
				<Logo height="80" />
			</Box>
			{/* </Box> */}
			{formHeader}
			<form className={classes.form} onSubmit={handleSubmit} noValidate>
				{formFields.map((f, i) => {
					return (
						<TextField
							key={i}
							margin="normal"
							id={f.item}
							value={fields[f.item]}
							onChange={handleChange}
							onBlur={handleValidation}
							className={clsx(classes.textField, classes[f.item])}
							label={f.label}
							type={f.type === "password" && showPassword ? "text" : f.type}
							name={f.item}
							fullWidth
							error={validation[f.item].length > 0}
							helperText={validation[f.item]}
							{...(f.type === "password" && {
								InputProps: {
									endAdornment: (
										<PasswordVisibility
											showPassword={showPassword}
											onClick={() => toggleShowPassword((st) => !st)}
										/>
									),
								},
							})}
						/>
					);
				})}
				<Typography
					variant="body1"
					component="div"
					sx={{
						textAlign: "center",
						fontWeight: "500",
						textDecoration: "underline",
						color: "#02D464",
						cursor: "pointer",
					}}
					onClick={() => {
						setOpenForget(true);
					}}
				>
					forgot your password?
				</Typography>
				<div className={classes.formActions}>
					<Button
						disabled={isSubmitDisabled()}
						type="submit"
						variant="contained"
						color="primary"
						sx={{ color: "#fff" }}
					>
						Log in
					</Button>
				</div>
			</form>
		</Paper>
	);
}

export default Login;
