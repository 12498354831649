import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import paths from "routes/paths";
import theme from "theme";
import { Link, useLocation } from "react-router-dom";

export default function SwipeableTemporaryDrawer({ state, setState }) {
	// const [state, setState] = React.useState(true);
	const location = useLocation();

	const toggleDrawer = (open) => (event) => {
		if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		setState(open);
	};

	const list = () => (
		<Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
			<List>
				{paths.map((item, index) => {
					const { tab, path } = item;
					return (
						tab && (
							<Link key={index} to={path} style={{ textDecoration: "none", color: theme.palette.text.primary }}>
								<ListItem disablePadding button key={tab.label} selected={path === location.pathname}>
									<ListItemButton key={tab.label}>
										<ListItemIcon>
											<Icon>{tab.icon}</Icon>
										</ListItemIcon>
										<ListItemText primary={tab.label} />
									</ListItemButton>
								</ListItem>
								<Divider />
							</Link>
						)
					);
				})}
			</List>
		</Box>
	);

	return (
		<div>
			<SwipeableDrawer
				PaperProps={{
					sx: { width: "20%" },
				}}
				anchor="left"
				open={state}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
			>
				{list()}
			</SwipeableDrawer>
		</div>
	);
}
