import React from "react";
// import AnalyzeDocument from "pages/AnalyzeDocument/AnalyzeDocument";
import AzureDocuments from "pages/AzureDocuments/AzureDocuments";
import Users from "pages/Users/Users";
import Ignored from "pages/Ignored/Ignored";
import DirectSearch from "pages/DirectSearch/DirectSearch";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  // {
  // 	path: "/",
  // 	isPrivate: true,
  // 	exact: true,
  // 	component: <AnalyzeDocument />,
  // 	tab: {
  // 		label: "Start Coding",
  // 		icon: "medical_services",
  // 	},
  // },
  {
    path: "/",
    isPrivate: true,
    exact: true,
    component: <AzureDocuments />,
    tab: {
      label: "Coded Documents",
      icon: "medical_information",
    },
  },
  {
    path: "/users",
    isPrivate: true,
    exact: true,
    component: <Users />,
    tab: {
      label: "Users",
      icon: "people",
    },
  },
  {
    path: "/ignored",
    isPrivate: true,
    exact: true,
    component: <Ignored />,
    tab: {
      label: "Ignored",
      icon: "do_not_disturb_off",
    },
  },
  {
    path: "/direct-search",
    isPrivate: true,
    exact: true,
    component: <DirectSearch />,
    tab: {
      label: "Direct Search",
      icon: "content_paste_search",
    },
  },
];
