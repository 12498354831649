import { Box, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { snackbarErrorMsg } from "config/constants";
import { isEmpty, toLower } from "lodash";
import { searchByCodeAndDescription } from "models/Code";
import { submitUserDataSource } from "models/DataSource";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import snackbar from "utils/snackbar";

function AddDescription({ open, setOpen, current, fetchDocument }) {
  const { currentUser } = useSelector((state) => state.auth);
  const [code, setCode] = useState({});
  const [codes, setCodes] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const fetchCodes = async (type) => {
    try {
      if (searchKey.length > 0) {
        setLoading(true);
        const data = await searchByCodeAndDescription(type, searchKey);
        setCodes(data);
      } else {
        setCodes([]);
      }
    } catch (error) {
      snackbar.error(error?.message || "Can't Fetch Search List.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(currentUser?.user_type) && current?.kind) {
      const type = currentUser?.user_type[toLower(current?.kind)];
      fetchCodes(type);
    }
  }, [currentUser?.user_type, current?.kind, searchKey]);

  const handleSubmit = async () => {
    try {
      setOpen(false);
      const data = {
        name: currentUser?.user_type[toLower(current?.kind)],
        entityId: code?.code,
      };
      await submitUserDataSource(current?.document_id, current?.id, data);
      await fetchDocument(current?.document_id);
      snackbar.success("Description Created Successfully.");
    } catch (error) {
      snackbar.error(snackbarErrorMsg);
      console.log(error);
    } finally {
      resetForm();
    }
  };

  const resetForm = () => {
    setCode({});
    setCodes([]);
    setSearchKey("");
  };

  const isDisabled = isEmpty(code) || loading;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        setOpen(false);
        resetForm();
      }}
      aria-labelledby="add-user-first-name"
      aria-describedby="add-user-description"
    >
      <DialogTitle id="add-user-first-name">Add New Description</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Autocomplete
            value={code}
            onChange={(event, newValue) => setCode(newValue)}
            onInputChange={(e, value) => setSearchKey(value)}
            sx={{ my: 2 }}
            id="code"
            options={codes}
            fullWidth
            // disabled={loading}
            filterOptions={(e) => e}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => <TextField {...params} label="Code" />}
            getOptionLabel={(opt) =>
              opt?.id
                ? `(${opt?.code}) - ${opt?.description} ----- (#${opt?.id})`
                : current?.text
            }
          />
          {loading && (
            <Box sx={{ mx: 2 }}>
              <CircularProgress size="2rem" />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            resetForm();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isDisabled}
          variant="contained"
        >
          Add Description
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddDescription;
