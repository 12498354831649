import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Layout from "components/Layout/Layout";
import { deleteUser, getUserTypes, getUsers } from "models/User";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import WarningDialog from "components/WarningDialog/WarningDialog";
import snackbar from "utils/snackbar";
import { snackbarErrorMsg } from "config/constants";
import _ from "lodash";
import AddUser from "components/AddUser/AddUser";
import EditUser from "components/EditUser/EditUser";
import { format } from "date-fns";
import { useSelector } from "react-redux";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const searchPaths = [
  "id",
  "name",
  "username",
  "type",
  "user_type.name",
  "user_type.diagnosis",
  "user_type.procedure",
  "user_type.medication",
  "submissions",
];

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    align: "center",
    label: "ID",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Name",
  },
  {
    id: "username",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Username",
  },
  {
    id: "userType",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "User Type",
  },
  {
    id: "codeType",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Code Type",
  },
  {
    id: "submissions",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Submissions",
  },
  {
    id: "active",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Active",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Created At",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function getKeyByValue(object, value) {
  return (
    searchPaths.findIndex((spath) =>
      String(_.get(object, spath))
        .toLowerCase()
        .includes(String(value).toLowerCase())
    ) !== -1
  );
}

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setUsersPerPage] = React.useState(5);
  const [rows, setUsers] = React.useState([]);
  const [mainRows, setMainRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [searchKey, setSearchKey] = React.useState("");
  const [userTypes, setUserTypes] = React.useState([]);

  const { currentUser } = useSelector((state) => state.auth);

  const fetchUserTypes = async () => {
    const result = await getUserTypes();
    setUserTypes(result);
  };

  React.useEffect(() => {
    fetchUsers();
    fetchUserTypes();
  }, []);

  const handleSearchKeyChange = (e) => {
    setSearchKey(e.target.value);
  };

  React.useEffect(() => {
    if (!searchKey) {
      setUsers(mainRows);
    } else {
      setUsers(mainRows.filter((r) => getKeyByValue(r, searchKey)));
    }
  }, [searchKey, mainRows]);

  const fetchUsers = async () => {
    const data = await getUsers();
    setUsers(data);
    setMainRows(data);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setUsersPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async () => {
    try {
      setWarningDialogOpen(false);
      snackbar.toast("Deleting User...");
      await deleteUser(selectedRow?.id);
      await fetchUsers();
      snackbar.toast("User Deleted Successfully.");
    } catch (error) {
      snackbar.error(error?.response?.data?.data?.message || snackbarErrorMsg);
      console.log(error);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const ctaButton = (
    <Button
      variant="contained"
      size="large"
      sx={{
        bgcolor: "#fff",
        color: "primary.main",
        ":hover": {
          bgcolor: "#fff",
        },
      }}
      onClick={() => setOpen(true)}
    >
      Add User
    </Button>
  );

  return (
    <Layout cta={ctaButton}>
      <AddUser
        open={open}
        setOpen={setOpen}
        fetchUsers={fetchUsers}
        userTypes={userTypes}
      />
      <EditUser
        open={editOpen}
        setOpen={setEditOpen}
        fetchUsers={fetchUsers}
        user={selectedRow}
        userTypes={userTypes}
      />
      <WarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        containedBtn="close"
        onProceed={() => handleDelete()}
        title="Remove User?"
        description="You are about to Remove a User, this action is irreversible."
        closeLabel="Cancel"
        proceedLabel="Yes, Remove!"
      />
      <TextField
        sx={{ m: 2, width: "25%" }}
        value={searchKey}
        autoComplete="remove-it"
        onChange={handleSearchKeyChange}
        id="search"
        label="Search"
        type="search"
        // fullWidth
        variant="outlined"
      />
      <Paper>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell
                        align="center"
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        <b>{row?.id}</b>
                      </TableCell>
                      <TableCell align="center">{row?.name}</TableCell>
                      <TableCell align="center">{row?.username}</TableCell>
                      <TableCell align="center">
                        {_.upperFirst(row?.type?.toLowerCase())}
                      </TableCell>
                      <TableCell align="center">
                        <b>{row?.user_type?.name || "-"}</b>
                      </TableCell>
                      <TableCell align="center">
                        {row?.type === "ADMIN" ? "-" : row?.submissions}
                      </TableCell>
                      <TableCell align="center">
                        {row?.active ? (
                          <b style={{ color: "green" }}>On</b>
                        ) : (
                          <b style={{ color: "red" }}>Off</b>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {format(
                          new Date(row?.created_at),
                          "dd MMM yyyy - hh:mm a"
                        )}
                      </TableCell>
                      {currentUser.id !== row.id && (
                        <TableCell align="center">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedRow(row);
                              setEditOpen(true);
                            }}
                            edge="end"
                            aria-label="Edit"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedRow(row);
                              setWarningDialogOpen(true);
                            }}
                            edge="end"
                            aria-label="Delete"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Layout>
  );
}
