import { Collapse, FormControl, InputLabel, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import PasswordVisibility from "components/PasswordVisibility/PasswordVisibility";
import { snackbarErrorMsg } from "config/constants";
import { upperFirst } from "lodash";
import { checkUserName, createUser } from "models/User";
import { useState } from "react";
import snackbar from "utils/snackbar";

function AddUser({
  open,
  setOpen,
  fetchUsers,
  corporate = false,
  userTypes = [],
}) {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [showPassword, toggleShowPassword] = useState(false);
  const [submissions, setSubmissions] = useState(0);
  const [userType, setUserType] = useState("");

  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidRePassword, setInvalidRePassword] = useState(false);

  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState("");
  const [invalidRePasswordMessage, setInvalidRePasswordMessage] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleRePasswordChange = (e) => {
    setRePassword(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  const handleSubmissionsChange = (e) => {
    const val = parseInt(e.target.value);
    setSubmissions(val);
  };

  const handleSubmissionsBlur = (e) => {
    const val = parseInt(e.target.value);
    setSubmissions(!val || val < 0 ? 0 : val > 1000 ? 1000 : val);
  };

  const validatePassword = () => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    const check = !strongRegex.test(password);
    setInvalidPassword(check);
    if (check) {
      setInvalidPasswordMessage(
        "The string must be eight characters or longer. at least 1 lowercase, 1 uppercase, 1 numeric and 1 special character."
      );
    } else {
      setInvalidPasswordMessage("");
    }
  };

  const validateRePassword = () => {
    const check = rePassword !== password;
    setInvalidRePassword(check);
    if (check) {
      setInvalidRePasswordMessage(
        "Re-Type Password and Password is not Identical."
      );
    } else {
      setInvalidRePasswordMessage("");
    }
  };

  const handleSubmit = async () => {
    try {
      const checkUserUsername = await checkUserName({
        username: username.toLowerCase(),
      });
      if (checkUserUsername) {
        snackbar.error("User Username Already Exists.");
        return;
      }

      setOpen(false);

      const data = {
        name,
        type,
        username: username.toLowerCase(),
        password,
        submissions,
        userType,
      };

      await createUser(data);

      await fetchUsers();

      snackbar.toast("User Created Successfully.");
    } catch (error) {
      snackbar.error(error?.response?.data?.data?.message || snackbarErrorMsg);
      console.log(error);
    } finally {
      resetForm();
    }
  };

  const resetForm = () => {
    setName("");
    setType("");
    setUsername("");
    setPassword("");
    setRePassword("");
    setInvalidPassword(false);
    setInvalidRePassword(false);
    setSubmissions(5);
    setUserType("");
  };

  const isDisabled =
    !name ||
    !type ||
    !username ||
    !password ||
    !rePassword ||
    invalidPassword ||
    invalidRePassword ||
    (type === "CLIENT" && (!submissions?.toString() || !userType));

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        setOpen(false);
        resetForm();
      }}
      aria-labelledby="add-user-first-name"
      aria-describedby="add-user-description"
    >
      <DialogTitle id="add-user-first-name">Add New Client</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          value={name}
          autoComplete="off"
          onChange={handleNameChange}
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="normal"
          value={username}
          autoComplete="off"
          onChange={handleUsernameChange}
          id="username"
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="normal"
          id="create-user-password"
          value={password}
          autoComplete="off"
          onChange={handlePasswordChange}
          onBlur={() => {
            validatePassword();
            validateRePassword();
          }}
          label="Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          error={invalidPassword}
          helperText={invalidPasswordMessage}
          {...{
            InputProps: {
              endAdornment: (
                <PasswordVisibility
                  showPassword={showPassword}
                  onClick={() => toggleShowPassword((st) => !st)}
                />
              ),
            },
          }}
        />
        <TextField
          margin="normal"
          id="create-user-re-password"
          value={rePassword}
          autoComplete="remove-it"
          onChange={handleRePasswordChange}
          label="Re-Type Password"
          type={showPassword ? "text" : "password"}
          firstName="rePassword"
          fullWidth
          onBlur={() => {
            validatePassword();
            validateRePassword();
          }}
          error={invalidRePassword}
          helperText={invalidRePasswordMessage}
          {...{
            InputProps: {
              endAdornment: (
                <PasswordVisibility
                  showPassword={showPassword}
                  onClick={() => toggleShowPassword((st) => !st)}
                />
              ),
            },
          }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="type">Type</InputLabel>
          <Select
            labelId="type"
            id="type-select"
            value={type}
            label="Type"
            onChange={handleTypeChange}
          >
            {["ADMIN", "CLIENT"].map((val) => (
              <MenuItem value={val}>{upperFirst(val.toLowerCase())}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Collapse timeout={400} in={type === "CLIENT"}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="user-type">User Type</InputLabel>
            <Select
              labelId="user-type"
              id="user-type-select"
              value={userType}
              label="User Type"
              onChange={handleUserTypeChange}
            >
              {userTypes.map((ut) => (
                <MenuItem value={ut?.id}>
                  <b>{ut?.name}</b>
                  <small style={{ marginLeft: 20 }}>
                    {[
                      `Diagnosis: ${ut?.diagnosis}`,
                      `Procedure: ${ut?.procedure}`,
                      `Medication: ${"- - - - -"}`,
                    ].join(", ")}
                  </small>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            margin="normal"
            value={submissions}
            autoComplete="remove-it"
            onChange={handleSubmissionsChange}
            onBlur={handleSubmissionsBlur}
            id="submissions"
            label="Submissions Limit"
            type="number"
            fullWidth
            variant="outlined"
          />
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={isDisabled}
          variant="contained"
        >
          Add User
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddUser;
