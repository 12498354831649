import * as React from "react";
import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AzureOriginDocument from "components/AzureOriginDocument/AzureOriginDocument";
import { getUserCompareDocument } from "models/Document";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CompareDocument({
  openCompare,
  handleCloseCompare,
  selectedRow,
}) {
  const [row, setRow] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const fetchDocument = async (id) => {
    setLoading(true);
    const doc = await getUserCompareDocument(id);
    console.log(doc);
    setRow(doc);
    setLoading(false);
  };

  React.useEffect(() => {
    if (selectedRow) fetchDocument(selectedRow);
  }, [selectedRow]);

  return (
    <Dialog
      fullScreen
      open={openCompare}
      onClose={handleCloseCompare}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseCompare}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {row?.job_name} Compare Versions
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {loading && (
          <Box
            sx={{
              position: "fixed",
              zIndex: "1000000000000",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              height: "100%",
              width: "100%",
              bgcolor: "#000000B3",
              overflow: "auto",
            }}
          >
            <CircularProgress size={100} />
            <Typography
              variant="bod1"
              component="div"
              color="#FFF"
              mt={5}
              fontWeight="bold"
            >
              Preparing Your Data Please Wait...
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
          }}
        >
          {row.entityOrigin === null ? (
            <Box>
              <Typography color="#555" component="div" variant="h5">
                This document has no origin version.
              </Typography>
            </Box>
          ) : (
            <AzureOriginDocument row={row} selectedIndex={0} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
            bgcolor: "#ddd",
          }}
        >
          <AzureOriginDocument row={row} selectedIndex={1} />
        </Box>
      </Box>
    </Dialog>
  );
}
