import api from "utils/api";

export const getUserDocuments = async () => {
  return await api
    .get("/documents")
    .then((res) => {
      const {
        data: {
          data: { documents },
        },
      } = res;
      return documents;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const submitUserDocuments = async (payload) => {
  return await api
    .post("/documents", payload)
    .then((res) => {
      const {
        data: {
          data: { documentId },
        },
      } = res;
      return documentId;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getUserDocument = async (id) => {
  return await api
    .get(`/documents/${id}`)
    .then((res) => {
      const {
        data: {
          data: { document },
        },
      } = res;
      return document;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getUserCompareDocument = async (id) => {
  return await api
    .get(`/documents/${id}/compare`)
    .then((res) => {
      const {
        data: {
          data: { document },
        },
      } = res;
      return document;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
