import api from "utils/api";

export async function getUsers() {
  return api
    .get("/users")
    .then((res) => {
      const {
        data: {
          data: { users },
        },
      } = res;
      return users;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getUserTypes() {
  return api
    .get("/user-types")
    .then((res) => {
      const {
        data: {
          data: { userTypes },
        },
      } = res;
      return userTypes;
    })
    .catch((err) => {
      throw err;
    });
}

export async function checkUserName(payload) {
  return api
    .post("/users/check", payload)
    .then((res) => {
      const {
        data: {
          data: { status },
        },
      } = res;
      return status;
    })
    .catch((err) => {
      throw err;
    });
}

export async function createUser(payload) {
  return api
    .post("/users", payload)
    .then((res) => {
      const {
        data: {
          data: { user },
        },
      } = res;
      return user;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteUser(id) {
  return api
    .delete(`/users/${id}`)
    .then((res) => {
      const {
        data: {
          data: { message },
        },
      } = res;
      return message;
    })
    .catch((err) => {
      throw err;
    });
}

export async function editUser(id, payload) {
  return api
    .patch(`/users/${id}`, payload)
    .then((res) => {
      const {
        data: {
          data: { user },
        },
      } = res;
      return user;
    })
    .catch((err) => {
      throw err;
    });
}
